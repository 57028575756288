import {
  Box,
  Button,
  Heading,
  Icon,
  Link,
  SimpleGrid,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { ArrowUpRight } from "@phosphor-icons/react";
import axios from "axios";
import { useEffect, useState } from "react";
import BlogPostCard from "../components/BlogPostCard";
import ComponentSpinner from "../components/ComponentSpinner";
import Container from "../components/Container";
import { MoreTrendingBlog } from "../components/MoreTrendingBlog";
import { PrimaryTrendingBlog } from "../components/PrimaryTrendingBlog";
import { BlogPost, BlogSection } from "../const/types";
import cr from "../lib/request";
import useScreenHeight from "../lib/useGetScreenHeight";

type Props = {
  preData: BlogSection;
};

export default function Blog({ preData }: Props) {
  const [data, setData] = useState<BlogPost[] | null>(null);
  const [primaryTrending, setPrimaryTrending] = useState<BlogPost | undefined>(
    undefined
  );
  const [moreTrending, setMoreTrending] = useState<BlogPost[] | undefined>(
    undefined
  );
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    setLoading(true);
    const url = "api/blog/post/allpostlistpublic";
    cr.get(url)
      .then((r) => {
        if ((r.status = 200)) {
          setData(r.data.data);
        }
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    const url = `${process.env.REACT_APP_WEB_API_BASE_URL}api/blog/post/getlandingdata`;
    axios
      .get(url)
      .then((response) => {
        setPrimaryTrending(response.data.data.topposts[0]);
        setMoreTrending(response.data.data.topposts);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <VStack
      id="blogSection"
      minH={useScreenHeight() < 900 ? "100vh" : ""}
      // mt={"-72px"}
      overflow={"hidden"}
    >
      <Box w={"100%"} py={12}>
        {!loading && data ? (
          <Container>
            <Heading textAlign={"center"} display={"none"}>
              Blog
            </Heading>
            <Text
              textAlign={"center"}
              fontSize={[32, null, 34]}
              fontWeight={800}
              mb={6}
              alignSelf={"start"}
              justifyContent={"start"}
            >
              Trending Blog
            </Text>

            <Stack
              w={"100%"}
              direction={["column", null, null, "row"]}
              mb={6}
              gap={4}
            >
              <VStack w={["100%", null, null, "70%"]}>
                {primaryTrending && (
                  <PrimaryTrendingBlog data={primaryTrending} />
                )}
              </VStack>
              <SimpleGrid
                columns={[3, null, null, 1]}
                w={["100%", null, null, "30%"]}
              >
                {moreTrending?.slice(2, 5).map((content, i) => (
                  <MoreTrendingBlog key={i} data={content} />
                ))}
              </SimpleGrid>
            </Stack>

            <Text
              mt={6}
              textAlign={"center"}
              fontSize={[32, null, 34]}
              fontWeight={800}
              mb={6}
              alignSelf={"start"}
              justifyContent={"start"}
            >
              Blog Menarik Lainnya
            </Text>

            <SimpleGrid columns={[1, null, 2, 3]} gap={4}>
              {data?.map((content, i) => (
                <BlogPostCard key={i} data={content} />
              ))}
            </SimpleGrid>

            <Button
              w={"max-content"}
              variant={"ghost"}
              rightIcon={
                <Icon
                  as={ArrowUpRight}
                  fontSize={[18, null, 20]}
                  weight="bold"
                />
              }
              color={"p.500"}
              as={Link}
              href={preData?.linkAllBlog.konten}
              alignSelf={"flex-end"}
              mt={8}
            >
              Jelajahi Semua Blog Kubu.id
            </Button>
          </Container>
        ) : (
          <ComponentSpinner />
        )}
      </Box>
    </VStack>
  );
}
