import { Button, Heading, Image, Text, VStack } from "@chakra-ui/react";
import React from "react";
import Container from "../components/Container";
import { Link } from "react-router-dom";

export default function Soon() {
  return (
    <VStack minH={"100vh"} p={5} justify={"center"}>
      <Container>
        <Image
          src="/images/underConstruction.svg"
          maxW={"400px"}
          mb={4}
          mx={"auto"}
        />

        <Heading textAlign={"center"} mb={4}>
          Sedang Dalam Pengembangan
        </Heading>

        <Text textAlign={"center"} mb={4} opacity={0.5}>
          Tunggu sebentar ya! Kami bekerja keras agar halaman ini dapat segera
          hadir untuk Anda dengan peningkatan signifikan. Terima kasih atas
          pengertian dan kesabaran Anda. Tetap pantau untuk informasi lebih
          lanjut!
        </Text>

        <Button
          variant={"ghost"}
          colorScheme="ap"
          as={Link}
          to={"/"}
          w={"fit-content"}
          mx={"auto"}
        >
          Kembali ke halaman utama
        </Button>
      </Container>
    </VStack>
  );
}
