import { Image, VStack } from "@chakra-ui/react";
import React from "react";

export default function KubuSpinner() {
  return (
    <VStack>
      <Image
        src="/logo.png"
        w={"100px"}
        animation={"pulse 1000ms infinite"}
        borderRadius={8}
      />
    </VStack>
  );
}
