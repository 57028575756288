import {
  Badge,
  Box,
  Center,
  Image,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import parse from "html-react-parser";
import { Loker_Interface } from "../const/lokerInterface";
import useScreenWidth from "../lib/useGetScreenWidth";
import CryptoJS from "crypto-js";

interface Props {
  data: Loker_Interface;
}

export default function LokerCard({ data }: Props) {
  const sw = useScreenWidth();
  const encoded = CryptoJS.enc.Base64.stringify(
    CryptoJS.enc.Utf8.parse(data.loker_id)
  );

  const handleClick = () => {
    window.open(
      `https://employer.kubu.id/website/loker-free/${encoded}`,
      "_blank"
    );
  };

  return (
    <VStack
      bg={"white"}
      align={"stretch"}
      cursor={"pointer"}
      onClick={handleClick}
      p={4}
      className="clicky"
      transition={"200ms"}
      borderRadius={8}
    >
      <Center
        p={2}
        mb={2}
        borderRadius={"lg"}
        bg={"var(--p500a)"}
        w={"fit-content"}
        aspectRatio={1}
      >
        <Image
          src={data.cover}
          w={"100%"}
          maxW={"48px"}
          objectFit={"cover"}
          borderRadius={"full"}
          aspectRatio={1}
        />
      </Center>

      <Text
        fontSize={[20, null, 22]}
        fontWeight={700}
        lineHeight={1.4}
        noOfLines={2}
      >
        {data.job_position}
      </Text>

      <Box noOfLines={2} mb={4} lineHeight={1.4} opacity={0.5}>
        {parse(data.job_desc)}
      </Box>

      <Stack
        direction={["column", "column", "row", "row", "row"]}
        gap={2}
        mt={"auto"}
      >
        <Badge colorScheme="ap" w={"fit-content"}>
          <Text>{data.type}</Text>
        </Badge>
        <Badge colorScheme="ap" w={"fit-content"} maxW={"200px"}>
          <Text
            overflow={"hidden"}
            textOverflow={"ellipsis"}
            maxW={sw <= 460 ? "100px" : "200px"}
          >
            {data.city}
          </Text>
        </Badge>
      </Stack>
    </VStack>
  );
}
