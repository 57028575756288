import { VStack } from "@chakra-ui/react";
import React from "react";

export default function Container(props: any) {
  return (
    <VStack
      {...props}
      gap={0}
      width={"100%"}
      maxW={props.maxW || "1080px"}
      align={"stretch"}
      px={[4, 6, 8]}
      className="container"
      mx={"auto"}
    >
      <>{props.children}</>
    </VStack>
  );
}
