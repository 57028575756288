import {
  Button,
  Heading,
  Icon,
  Link,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";
import Container from "../components/Container";
import LokerCard from "../components/LokerCard";
import { Loker_Interface } from "../const/lokerInterface";
import { ArrowUpRight } from "@phosphor-icons/react";

interface Props {
  loker: Loker_Interface[] | undefined;
}

export default function Loker({ loker }: Props) {
  return (
    <VStack id="lokerSection" py={20} bg={"#FAFAFB"}>
      <Container>
        <Heading textAlign={"center"} display={"none"}>
          Layanan
        </Heading>

        <Text textAlign={"center"} fontSize={[32, null, 34]} fontWeight={800}>
          Lowongan Kerja
        </Text>

        <Text
          textAlign={"center"}
          fontSize={[20, null, 22]}
          fontWeight={"normal"}
          mb={12}
          opacity={0.5}
        >
          Jelajahi semua lowongan kerja yang tersedia di Kubu.id
        </Text>

        <SimpleGrid columns={[2, 2, 2, 3, 3]} gap={4}>
          {loker?.map((item, i) => (
            <LokerCard key={item.loker_id} data={item} />
          ))}
        </SimpleGrid>

        <Button
          w={"max-content"}
          variant={"ghost"}
          rightIcon={
            <Icon as={ArrowUpRight} fontSize={[18, null, 20]} weight="bold" />
          }
          color={"p.500"}
          as={Link}
          href={"https://employer.kubu.id/"}
          isExternal={true}
          alignSelf={"flex-end"}
          mt={8}
        >
          Jelajahi Semua Lowongan Kerja
        </Button>
      </Container>
    </VStack>
  );
}
