import {
  Box,
  Center,
  Heading,
  HStack,
  Image,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import Container from "../components/Container";
import { FiturSection } from "../const/types";
import FiturItemModal from "../components/FiturItemModal";

type Props = {
  data: FiturSection;
};

export default function Fitur({ data }: Props) {
  return (
    <VStack id="fiturSection" py={20} align={"flex-start"} bg={"var(--p500a)"}>
      <Container>
        <Heading textAlign={"center"} display={"none"}>
          Fitur
        </Heading>
        <Text
          textAlign={"center"}
          fontSize={[32, null, 34]}
          fontWeight={800}
          mb={12}
        >
          {data.judul.konten}
        </Text>

        <SimpleGrid columns={[1, null, 2]} gap={8} mb={8}>
          {data.fiturItems?.map((fitur, i) => (
            <Box key={i} mb={4}>
              <HStack align={"flex-start"} gap={[4, 5, 6]}>
                <Center
                  borderRadius={"full"}
                  p={2}
                  bg={"var(--p100)"}
                  flexShrink={0}
                  w={"40px"}
                  h={"40px"}
                  mt={-1}
                >
                  <Image
                    src={`${process.env.REACT_APP_MEDIA_BASE_URL}${fitur.icon.konten}`}
                    h={"40px"}
                    flexShrink={0}
                    alt={`Icon fitur Kubu mobile ${fitur.judul.konten}`}
                  />
                </Center>

                <Box pb={4}>
                  <Heading
                    as={"h3"}
                    fontWeight={800}
                    fontSize={[18, null, 20]}
                    mb={2}
                  >
                    {fitur.judul.konten}
                  </Heading>

                  <Text lineHeight={1.8} opacity={0.5} noOfLines={3} mb={8}>
                    {fitur.narasi.konten}
                  </Text>

                  <FiturItemModal data={fitur} />
                </Box>
              </HStack>
            </Box>
          ))}
        </SimpleGrid>
      </Container>

      <Box w={"100%"} px={[4, 6, 8]} overflow={"hidden"}>
        <HStack
          animation={"infiniteScrollX 30s linear infinite"}
          w={"max-content"}
          gap={6}
          py={8}
          mx={"auto"}
          pr={6}
          align={"stretch"}
        >
          {data.addon.map((addon, i) => (
            <HStack
              key={i}
              gap={6}
              scrollSnapAlign={"center"}
              border={"2px solid var(--p400)"}
              borderRadius={8}
              p={4}
              w={"max-content"}
              justify={"center"}
            >
              <Image
                src={`${process.env.REACT_APP_MEDIA_BASE_URL}${addon.img.konten}`}
                w={"30px"}
                alt={`Icon ${addon.narasi.konten}`}
              />
              <Text
                fontSize={16}
                fontWeight={600}
                maxW={"240px"}
                lineHeight={1.2}
                color={"p.400"}
              >
                {addon.narasi.konten}
              </Text>
            </HStack>
          ))}
          {data.addon.map((addon, i) => (
            <HStack
              key={i}
              gap={6}
              scrollSnapAlign={"center"}
              border={"2px solid var(--p400)"}
              borderRadius={8}
              p={4}
              w={"max-content"}
              justify={"center"}
            >
              <Image
                src={`${process.env.REACT_APP_MEDIA_BASE_URL}${addon.img.konten}`}
                w={"30px"}
                alt={`Icon ${addon.narasi.konten}`}
              />
              <Text
                fontSize={16}
                fontWeight={600}
                maxW={"240px"}
                lineHeight={1.2}
                color={"p.400"}
              >
                {addon.narasi.konten}
              </Text>
            </HStack>
          ))}
        </HStack>
      </Box>
    </VStack>
  );
}
